.main-content {
  max-width: 2590px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  margin-top: 81px;
  @include media-breakpoint-down(sm) {
    margin-top: 5rem;
  }
}

.soon-wrapper {
  width: 50%;
  text-align: center;
  margin: 5px auto 0;
  @include media-breakpoint-down(sm) {
    width: 80%;
  }
}

.soon-title {
  color: #000;
  font-size: 30px;
  font-weight: bold;
}

.isCurrentPage {
  p {
    font-weight: bold !important;
  }
  color: #5da5e0 !important;
}

.sample-ad {
  width: 100%;
  display: flex;
  flex: 1;
  height: 200px;
  background-size: 100%;
  // background-repeat: no-repeat;
  // background-image: url("../img/ad-bg.png");
  position: relative;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.dl-btn {
  border-radius: 5px;
  overflow: hidden;
  img {
    object-fit: cover;

    @include media-breakpoint-up(xl) {
      width: 140px;
      height: 40px;
    }
    @include media-breakpoint-down(lg) {
      width: 9vw;
      height: 2.5vw;
    }
  }
}

.dl-ios {
  position: absolute;
  top: 0;

  @include media-breakpoint-up(xl) {
    right: 160px;
    transform: translateY(65%);
  }
  @include media-breakpoint-down(lg) {
    right: 11vw;
    transform: translateY(80%);
  }
}
.dl-google {
  position: absolute;
  top: 0;
  @include media-breakpoint-up(xl) {
    transform: translateY(65%);
    right: 10px;
  }
  @include media-breakpoint-down(lg) {
    right: 0.5vw;
    transform: translateY(80%);
  }
}

.activities-wrapper {
  @media (min-width: 768px) and (max-width: 1079.98px) {
    overflow-x: hidden;
  }
  @include media-breakpoint-down(sm) {
    overflow-x: scroll;
  }
}
