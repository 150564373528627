.badge-outline-gray {
  border: 1px solid $gray-400;
  color: $gray-400;
  background: transparent;
}

.badge-outline-success {
  border: 1px solid $green-100;
  color: $green-100;
  background: transparent;
}
.badge-outline-failed {
  border: 1px solid $red-100;
  color: $red-100;
  background: transparent;
}

.badge-ongoing {
  color: #ffffff;
  background-color: #14b8a6;
}
