.icon-password {
  width: 24px;
  height: 24px;
  fill: $gray-500;
}

.icon-action {
  width: 20px;
  height: 20px;
  fill: $white;
}

.icon-menu {
  width: 24px;
  height: 24px;
  fill: $gray-500;
}

.main-menu {
  width: 24px;
  height: 24px;
  fill: $gray-700;
}

.icon-add {
  font-size: 15px;
}

.icon-delete-sm {
  font-size: 15px;
  color: #b90000 !important;
}

.icon-delete {
  color: #b90000;
  font-size: 20px;
}

.icon-view {
  color: $apple-green;
  font-size: 18px;
}

.icon-circle {
  background: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100px;
  box-shadow: 0 0 1px 3px #fff;

  @include media-breakpoint-down(sm) {
    width: 20px;
    height: 20px;
  }

  &.text-danger {
    background: lighten($color-warning, 49%);
  }

  &.text-success {
    background: lighten($color-success, 45%);
  }

  &.fa-check:before {
    @include media-breakpoint-down(sm) {
      top: -6px !important;
      left: 0 !important;
      position: absolute !important;
    }
  }
}

.cashIn {
  fill: #7790cb;
}