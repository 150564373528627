.form-control {
  background: $white;
  border: 1px solid $gray-400;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 24px;
  font-size: 14px;
  color: $gray-800;
  height: 44px;
  box-shadow: 0 1px 0 0 $gray-100;
  &::placeholder {
    color: $gray-300;
  }
  &.form-control-lg {
    font-size: 16px;
    line-height: 32px;
    padding: 8px 16px;
    height: 48px;
  }
  &:hover {
    border-color: $gray-500;
    outline: none;
    box-shadow: none;
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.form-search {
  position: relative;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border-radius: 10px;

  .form-control-icon {
    position: absolute;
    top: 7px;
    left: 15px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
  .search-input {
    padding: 10px 16px 10px 60px;
    width: 100%;
    background: #f2f7ff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #4c608598;
    font: normal normal 600 12px/18px Poppins;
    &::placeholder {
      color: #4c608598;
    }
    &:hover {
      border-color: $gray-500;
      outline: none;
      box-shadow: none;
    }
  }
}

.permit-span {
  font: normal normal 600 12px/18px Poppins;
  color: #323943;
  text-align: left;
}

.transaction-title {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #323943;
}

.transaction-value {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #9fa2a7;
  margin-top: 8px;
}

.colon-span {
  margin-left: 42px;
  margin-right: 23px;
}

.colon-span-date {
  margin-left: 87px;
  margin-right: 23px;
}

.colon-span-amount {
  margin-left: 71px;
  margin-right: 23px;
}

.form-search-business {
  position: relative;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid $gray-100;

  .form-control-icon {
    position: absolute;
    top: 7px;
    left: 325px;
    .icon {
      color: $color-primary;
      width: 16px;
      height: 16px;
    }
  }
  .search-input {
    padding: 10px 16px 10px 10px;
    width: 250px;
    background: #fff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 10px;
    font-size: 25px;
    line-height: 20px;
    color: #4c608598;
    font: normal normal 600 12px/18px Poppins;
    &::placeholder {
      color: #4c608598;
    }
    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

.border-business {
  border: 1px solid #5272aa;
  border-radius: 10px;
}

.border-activities {
  border: 1px solid #9fa2a7;
  border-radius: 10px;
}

.value-table {
  word-wrap: break-word;
}

.upper-input {
  margin-top: -10px;
}
