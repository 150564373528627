.transaction-item {
  font-size: 13px !important;
  min-height: 500px;
  h3 {
    color: #4c6085;
    font-size: 18px;
    font-weight: 500;
  }
  .highlight {
    background-color: $gray-100;
    padding: 20px 0px;
    color: #4c6085;
    .transaction-date {
      font-size: 16px;
      font-weight: 500;
    }
    .transaction-time {
      font-size: 12px;
      font-weight: 500;
    }
    .reference-no {
      font-size: 28px;
      font-weight: 500;
      color: $dark-blue;
      div {
        @include media-breakpoint-down(sm) {
          padding: 5px 10px;
        }
      }
    }
    .price {
      font-size: 28px;
      font-weight: 500;
      color: $color-primary;
    }
    .status {
      span {
        padding: 8px 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
      }
      .pending {
        background-color: #d8d8d8;
        color: #a6a6a6;
      }
      .paid {
        background: $color-primary;
        color: $white;
      }
    }
  }
  .inner-detail {
    span {
      color: $dark-blue;
    }
  }
  .transaction-no {
    font-size: 18px;
    font-weight: 500;
  }
}

.input-bg {
  color: $color-secondary;
  background-color: $alice-blue;
  border-color: 1px $color-primary;
}

.bg-pending {
  background-color: #ffb803;
}

.bg-verified {
  background-color: #54D468;
}

.bg-ongoing {
  background-color: #14B8A6;
}

.bg-rejected {
  background-color: #DB4343;
}

