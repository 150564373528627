.modal {
  overflow-y: auto;
  z-index: 7777;
  display: block;
  pointer-events: "none";

  .modal-open {
    overflow: hidden;
  }

  .modal-content {
    border-radius: 0.4em;
    // overflow: hidden;
    margin: 20px 0 30px;
  }

  .modal-header {
    background: $totem-pole;
    color: $white;

    .close {
      color: $white;
      outline: none;
      opacity: 1;
    }
  }
}

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
}

.modal-xl {
  max-width: 1200px;
}

.border-dotted {
  border-style: dotted;
}

.modal-pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  .soa {
    padding: 20px;
  }
}