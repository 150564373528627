/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c9c9c9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before,
.slider .number {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #54D468;
}

input:disabled+.slider {
  cursor: not-allowed;
}

input:focus+.slider {
  box-shadow: 0 0 1px $color-success;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked+.slider:before,
input:checked+.slider .number {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  margin-left: 4px;
}

.slider .number {
  background: none;
  color: #606774;
  font-size: 11px;
  left: 10px;
  top: 5px;
}
