.generic-list-container {
  border-radius: 10px;
  // background-position-y: $gray-050;
  background-color: #fff;
  box-shadow: 4px 6px 10px #eff4fb;
}

.card {
  border-radius: 12px;
  background-position-y: $gray-050;
  box-shadow: 4px 6px 10px #eff4fb;
  padding: 16px;
  border: none;

  @media (min-width: 768px) and (max-width: 1079.98px) {
    padding: 5px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px;
  }

  .card-body {
    @media (min-width: 768px) and (max-width: 1079.98px) {
      padding: 5px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  .card-header {
    @media (min-width: 768px) and (max-width: 1079.98px) {
      padding: 5px;
    }
  }

  .card-footer {
    @media (min-width: 768px) and (max-width: 1079.98px) {
      padding: 5px;
    }
  }

  &.no-radius-bottom {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &.no-radius-top {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  &.card-border-bottom {
    border-bottom: 2px solid #ecedef;
  }

  &.card-box {
    padding: 16px;
    box-shadow: none;
  }

  &.active {
    background-color: $totem-pole;
    color: $white;
  }

  &.card-transparent {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    border: 0;
  }

  &>.header {
    margin-bottom: 16px;

    &>.title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
}

.arrow-box {
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 1px 3px 0 #a1a3a7;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 10%;
    right: -20px;
    box-sizing: border-box;

    border: 0.7em solid black;
    border-color: white;

    transform-origin: 0 0;
    transform: rotate(45deg);

    box-shadow: 2px -2px 1px 0.5px #f1f1f1;
  }
}

.card-img-top {
  object-fit: cover;
  object-position: top;
}

.card-main {
  border-radius: 5px !important;
  min-height: 700px;
}

.card {
  border-radius: 0px !important;
  position: relative;
  background-position-y: #fbfbfd;
  border-radius: 5px !important;
  border: 2px solid #fbfbfd !important;

  // box-shadow: 0 1px 3px 0 $gray-500;
  // padding: 16px;
  &.left-section {
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    &.sticky {
      position: sticky;
      top: 100px;
    }
  }

  &.right-section {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #fff !important;
    padding: 0;
    margin-left: 20px;
    margin-right: 15px;

    .card-section-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .right-section-content {
      background: white;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &.sticky {
      position: sticky;
      top: 0px;
    }
  }

  &.right-section-mobile {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #fff !important;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;

    .card-section-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .right-section-content {
      background: white;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &.sticky {
      position: sticky;
      top: 0px;
    }
  }

  &.card-box {
    padding: 16px;
    box-shadow: none;
  }

  &.active {
    background-color: $color-primary;
    color: $white;
  }

  &.card-transparent {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    border: 0;
  }

  &>.header {
    margin-bottom: 16px;

    &>.title {
      color: $color-primary;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }

  .card-section-content {
    // padding: 20px;

    .loading {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background: #fbfbfd;
      z-index: 5;

      .spinner {
        position: absolute;
        z-index: 10;
        font-weight: bolder;
        font-size: 40px;
        background: #eaeaea;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        left: 0;
        right: 0;
        padding-top: 12px;

        margin: 300px auto;
      }
    }
  }
}

.card-user {
  background: whitesmoke;
}

.card-profile {
  border-radius: 4px;
  background-position-y: #fafafa;
  // box-shadow: 0 1px 3px 0 #8b8d8f;
  border: none;
}

.row-profile {
  display: flex;
  flex-wrap: wrap;
}

.arrow-box {
  position: relative;
  padding: 1em;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 1px 3px 0 #c9ccd2;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 10%;
    right: -20px;
    box-sizing: border-box;

    border: 0.7em solid black;
    border-color: white;

    transform-origin: 0 0;
    transform: rotate(45deg);

    box-shadow: 2px -2px 1px 0.5px #f1f1f1;
  }
}

.hr-lg {
  border-top: 20px solid #eef0f4;
}

.card-gray {
  border-radius: 4px;
  background: $gray-200;
  border: $gray-200 3px solid;
}

.section-listing {
  height: calc(100vh - 250px);
}

.card-section-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  border: none !important;

  &>.search-filter {
    flex: 0 0 112px;
    padding: 16px;
  }

  &>.list-pagination {
    flex: 0 0 81px;
    padding: 24px;
  }

  &>.transaction-list {
    flex: 0 auto;
    overflow-y: auto;
  }
}

.white-bar {
  height: 50px;
  background: white;
  width: 100%;
  margin-bottom: 50px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: unset !important;
}

.nav-tabs {
  border: unset !important;
}

.right-section {
  .nav-link {
    background: #f4f4f4 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin: 0 3px;
    color: #9fa2a7;
    font-size: 12px;
    font-weight: bold;
  }

  .nav-link.active {
    background: $color-primary !important;
    color: #fff !important;
    border-bottom: 2px solid $color-primary !important;
  }

  .citizen-tabs {
    margin-top: 1px;
    border: 1px solid $color-primary !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.card-content-listing {
  font-size: 12px;
  padding: 24px 10px;

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16;
  }

  .search-container {
    color: #a0a2a8;
    border: unset !important;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background: #f2f7ff;
    padding: 10px 15px;

    .search-input {
      width: 100%;
      border: none;
      outline: none;
      background: #f2f7ff;
    }

    i {
      font-size: 25px;
      color: #4c6085;
    }
  }

  .transaction-list-company {
    font-size: 14px;
    text-transform: uppercase;
  }

  .transaction-list-name {
    text-transform: uppercase;
    color: $color-secondary;
    font-weight: 600;
  }

  .table-container {
    border: 1.5px solid #eef0f4;
    border-radius: 4px;
    min-height: 300px;
  }

  table {
    position: relative;

    .top-border {
      border-top: 2px solid #eef0f4;
    }

    .bottom-border {
      border-bottom: 2px solid #eef0f4;
    }

    thead {
      color: $black;
    }

    tbody {
      color: $color-secondary;
    }

    td {
      padding: 15px 5px;
      word-wrap: break-word;
    }

    .td-item {
      min-width: 150px;
    }
  }
}

.border-right-transaction {
  border-right: 1px solid $gray-200;
}