.permit-format {
  color: $dark-blue;
  font-size: 14px;
  padding: 30px 16px;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // text-align: center;
    padding: 0px;
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    margin: 0px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #4c6085;
  }

  h4 {
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
  }

  thead {
    font-size: 11px;
  }

  .top-label {
    font-size: 12px;
    color: $color-primary;
  }

  .label-link {
    font-size: 30px/46px;
    color: $dark-black;

    &:hover {
      text-decoration: none;
      color: $color-secondary;
    }
  }

  .header-container {
    margin-bottom: 35px;
    border-radius: 12px;
    background-position-y: $gray-050;
    box-shadow: 4px 6px 10px #eff4fb;
    padding: 16px;
    border: none;

    @media (min-width: 768px) and (max-width: 1079.98px) {
      padding: 5px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0px;
    }
  }

  .header-container-mobile {
    border-radius: 12px;
    background-color: #fafafa;
    box-shadow: 4px 6px 10px #eff4fb;
    padding: 16px;
    border: none;

    .label-text {
      color: $darker-blue;
      font-size: 16px/25px;
      font-weight: bold;
    }

    @media (min-width: 768px) and (max-width: 1079.98px) {
      padding: 5px;
    }

    @include media-breakpoint-down(sm) {
      padding: 18px;
    }
  }

  .header-icon {
    height: 45px;
    width: auto;
  }
}

.drag-drop {
  color: #979797;
  cursor: pointer;
  border-radius: 5px;
  border: 2px dashed #d9d9d9;
  border-style: dashed;
  outline: none;
  background: white;
  padding: 20px;
  transition: 200ms ease-in-out;

  &:hover {
    border: 2px dashed $color-primary;
    transition: 200ms ease-in-out;
  }

  input:focus,
  :active {
    outline: none !important;
    border: none;
  }
}

.requirements-list {
  margin-bottom: 15px;
}

.permit-wrapper {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  p {
    margin: 0;
    font-weight: bold;
  }
}

.application-class {
  padding-left: 13%;
  padding-right: 13%;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // text-align: center;
    padding-left: 2%;
    padding-right: 2%;
  }
}