.btn {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
}
.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: $color-secondary;
    border-color: $color-secondary;
    transition: 0.2s ease-in-out;
  }
}

.btn-sm {
  padding: 6px 12px;
  border: 1px solid transparent;
}

.btn-lg {
  font-size: 15px;
  padding: 24px;
  border: 1px solid transparent;
}

.btn,
.btn-sm,
.btn-md,
.btn-lg {
  transition: all 100ms ease-in-out;
  &:disabled {
    background-color: #bdb9bd;
    border-color: #bdb9bd;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
  }
  &:hover {
    transition: all 100ms ease-in-out;
    transform: scale(1.03);
  }
}

.btn-no-focus {
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  padding: 8px;
}

.btn-no-focus-default {
  outline: none;
  box-shadow: none;
  border: none;
  background: none;

  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}

.btn-outline-primary {
  background-color: transparent;
  border-color: $color-primary;
  color: $color-primary;
  transition: 0.2s ease-in-out;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.btn-blank {
  background: none;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    &.icon-view {
      color: $color-primary;
      fill: $color-secondary;
      &:hover {
        fill: $color-secondary;
      }
    }
  }
}

.back-button {
  color: $dark-blue;
  font-size: 20px;
  padding: 0px;
  &:hover {
    text-decoration: none;
    color: $color-primary;
    fill: $color-primary;
  }
  .text {
    margin-left: 20px;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 500;
  }
}

.back-button-permit {
  padding: 0px !important;
  margin-left: -25px;
}

.back-button-permit-appointment {
  margin-left: -25px !important;
}

button:focus {
  outline: none;
}

.transaction-button {
  &:disabled {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    cursor: not-allowed;
    & > .item-card {
      filter: alpha(opacity=50);
      opacity: 0.5;
    }
  }
}

.btn-list {
  @include media-breakpoint-down(sm) {
    width: 100%;
    & > .signup-btn,
    & > .login-btn {
      width: 100%;
    }
    & > .login-btn {
      margin: 15px 0;
    }
  }
}

.btn-pay-now {
  @include media-breakpoint-down(sm) {
    // margin-top: 10px;
  }
}

.isScrolled .btn-list .signup-btn {
  border-color: white;
  color: white;
}

.common-back {
  color: $color-primary;
  margin-top: 0px;
  i {
    margin-right: 8px;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    text-decoration: none;
  }
  .icon {
    background: $lighter-blue;
    border-radius: 50%;
    padding: 3px;
  }
}

.common-back-mobile {
  color: $color-primary;
  i {
    margin-right: 8px;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    text-decoration: none;
  }
}

.button-transaction {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: 100%;
}

.button-status {
  width: 120px;
  margin: 10px 7px 9px 0px;
  // height: 10px;
  border-radius: 3px;
  .button-text {
    text-align: center;
    font: normal normal 600 10px/16px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.button-profile {
  width: 150px !important;
}

.button-cancel {
  background-color: $title;
  color: $white;
  width: 244px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $title;
    color: $title;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.cancel-button {
  background-color: $title;
  color: $white;
  width: 25px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $title;
    color: $title;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-proceed {
  background-color: $darker-blue;
  color: $white;
  width: 244px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $darker-blue;
    color: $darker-blue;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.proceed-button {
  background-color: $darker-blue;
  color: $white;
  width: 100px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $darker-blue;
    color: $darker-blue;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-update {
  background-color: $color-primary;
  color: $white;
  width: 150px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $color-primary;
    color: $color-primary;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-delete {
  background-color: $darker-blue;
  color: $white;
  width: 150px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $darker-blue;
    color: $darker-blue;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-previous {
  background-color: $white;
  color: $color-primary;
  border: solid 1px $color-primary;
  width: 244px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-add-business {
  background-color: $color-primary;
  color: $white;
  border-radius: 5px;
  width: 244px;
  padding: 8px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $color-secondary;
    color: $color-secondary;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-search-business {
  background-color: $darker-blue;
  color: $white;
  border-radius: 5px;
  width: 244px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $darker-blue;
    color: $darker-blue;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.button-edit-profile {
  background-color: $color-primary;
  color: white;
  border-radius: 5px;
  width: 244px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $color-primary;
    color: white;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.search-year-button {
  margin-top: 33px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
}

.cancel-button {
  background-color: $title;
  color: $white;
  width: 100px;
  &:hover,
  &:active {
    background-color: transparent;
    border-color: $title;
    color: $title;
    transition: 0.2s ease-in-out;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(155, 192, 60, 0.5);
  }
}

.btn-mfa-back {
  font-size: 10px;
  font-weight: bold;
  padding: 8px 16px;
}